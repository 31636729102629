import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocalization } from 'gatsby-theme-i18n';
import type {
  HomepageTemplateQuery,
  MarkdownRemarkFrontmatterHeaderMenuHeaderLinks,
} from '../../../generated/types';
import { useWebsiteContext } from '../context';
import { filterContentByLocale } from '../i18n/utils';
import type { PpSeo } from '../layout';
import { BaseWrapper } from '../layout/BaseWrapper';
import { Footer } from '../layout/Footer';
import type { PpHeaderColors } from '../navbar/Header';
import { Header } from '../navbar/Header';

const query = graphql`
  query HomepageTemplate {
    english: markdownRemark(frontmatter: { layout: { eq: "info" }, locale: { eq: "en" } }) {
      frontmatter {
        headerMenu {
          headerLinks {
            headerLabel
            headerURL
            sublinks {
              sublinkLabel
              sublinkUrl
              footerOnly
            }
          }
          headerButtons {
            headerButton1
            headerButton2
          }
          hideLanguageSwitch
        }
        languageDialogButton
        languageDialogLabel
        legalCompanyName
        seo {
          defaultTitle
          description
          image
          siteURL
          titleTemplate
        }
        socialMenu {
          socialLinks {
            socialName
            socialURL
          }
        }
        newsletterDisclaimer
      }
    }
    spanish: markdownRemark(frontmatter: { layout: { eq: "info" }, locale: { eq: "es" } }) {
      frontmatter {
        headerMenu {
          headerLinks {
            headerLabel
            headerURL
            sublinks {
              sublinkLabel
              sublinkUrl
              footerOnly
            }
          }
          headerButtons {
            headerButton1
            headerButton2
          }
        }
        languageDialogButton
        languageDialogLabel
        legalCompanyName
        seo {
          defaultTitle
          description
          image
          siteURL
          titleTemplate
        }
        socialMenu {
          socialLinks {
            socialName
            socialURL
          }
        }
        newsletterDisclaimer
      }
    }
    lithuanian: markdownRemark(frontmatter: { layout: { eq: "info" }, locale: { eq: "lt" } }) {
      frontmatter {
        headerMenu {
          headerLinks {
            headerLabel
            headerURL
            sublinks {
              sublinkLabel
              sublinkUrl
              footerOnly
            }
          }
          headerButtons {
            headerButton1
            headerButton2
          }
        }
        languageDialogButton
        languageDialogLabel
        legalCompanyName
        seo {
          defaultTitle
          description
          image
          siteURL
          titleTemplate
        }
        socialMenu {
          socialLinks {
            socialName
            socialURL
          }
        }
        newsletterDisclaimer
      }
    }
  }
`;

type PpPage = {
  seo?: PpSeo | null;
} & PpHeaderColors &
  PpWC;

export function HomepageTemplate({
  backgroundColor,
  children,
  color,
  seo,
}: PpPage): ReactElement | null {
  const { locale } = useLocalization();
  const data = useStaticQuery<HomepageTemplateQuery>(query);
  const { setLanguageDialogInfo } = useWebsiteContext();
  const content = filterContentByLocale(locale, data);

  useEffect(() => {
    setLanguageDialogInfo({
      button: content?.languageDialogButton,
      label: content?.languageDialogLabel,
    });
  }, [setLanguageDialogInfo, content]);

  const { headerMenu, legalCompanyName, seo: defaultSeo, socialMenu } = content || {};

  const homepageTemplateSeo = {
    description: seo?.description || defaultSeo?.description,
    image: seo?.image || defaultSeo?.image,
    siteUrl: defaultSeo?.siteURL,
    title: seo?.title || defaultSeo?.defaultTitle,
    titleTemplate: seo?.titleTemplate || defaultSeo?.titleTemplate,
  };

  const theme = useTheme();

  return (
    <BaseWrapper
      FooterSlot={
        <Footer
          dense
          disclaimer={content?.newsletterDisclaimer}
          footerMenu={headerMenu?.headerLinks as MarkdownRemarkFrontmatterHeaderMenuHeaderLinks[]}
          legalCompanyName={legalCompanyName}
          socialMenu={socialMenu}
        />
      }
      HeaderSlot={
        <Header
          backgroundColor={
            backgroundColor || theme.palette.websiteBrand.greyscale.semiTransparentWhite
          }
          color={color || theme.palette.websiteBrand.greyscale.darkGrey}
          headerMenu={headerMenu}
        />
      }
      seoProps={homepageTemplateSeo}
    >
      {children}
    </BaseWrapper>
  );
}
